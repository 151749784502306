:root {
    --heigth-navbar: 48px;
}
@font-face 
  {
    font-family: "gotham-l";
    src: url("../styles/fonts/GOTHAM-LIGHT.TTF");
  }
@font-face 
  {
    font-family: "gotham-b";
    src: url("../styles/fonts/GOTHAM-BOLD.OTF");
  }
div.empresa-app img
{
  margin: 0 0.5rem;
}
* {
  text-decoration: none !important;
  padding: 0;
  margin: 0;
}
div.navbar-xl a,
div.playstore p
{
  font-family: 'gotham-l';
}
div.navbar-xl a
{
  margin: 0 0.3rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  box-sizing: border-box;
  text-shadow: 0 0 #3b3b3b;
}
div.playstore p
{
  margin-left: 0.5rem !important;
}
div.navbar-xl a:hover
{
  box-shadow: 0px 3px 0px -1px #6b6b6b;
  font-weight: bold;
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
  transition: all .2s ease-out!important;
}
.table {margin-bottom: 0;}
::-webkit-scrollbar {
    display: none;
}
body, html {
  background-color: #ffffff !important;
}
.container-fluid {
  padding: 0 !important;
}
.row {
  --bs-gutter-x: 0rem;
}
p, table {
  margin-bottom: 0 !important;
}
a {
  color: inherit !important;
}
.nav-root {
  z-index: 1;
  top: 0;
  position: fixed;
  background: #fff;
  width: 100vw;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.navbar-xl {
  align-items: center;
  display: flex;
  padding: 0 1.8em;
  text-decoration: none!important;
  height: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}


.navbar-sm a {
    color: #ffffff !important;
    transition: all 0.2s ease-out !important;
    padding: .5rem;
    margin: .2rem;
    font-family: 'gotham-b';
    font-size: 1.5rem;
    cursor: pointer;
}
.navbar-sm a:hover {
    transform: scale(1.1);
}
.playstore:hover p {
  border-bottom: 1px solid #000;
  transition: all 0.2s ease-out !important;
}

.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  max-width: 90%;
  height: 3em;
  margin-bottom: 1em;
  margin-top: 1em;
}
.nav-container img {
  width: 1em;
}
.shop {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.toggle-button {
    display:block;
    width:40px;
    height:40px;
    position:relative;
    cursor: pointer;
    box-sizing: border-box;
}
.toggle-button:before, .toggle-button:after {
    position:absolute;
    display:block;
    content:" ";
    width: calc(100% / 2);               
    box-sizing: border-box;
    left: calc(100% / 4);
    transition: all 0.2s ease-out;
}
.toggle-button:before {
    top: calc(100% / 4);
    height:calc(100% / 4);
    border-top:1px solid black;
    border-bottom:1px solid black;
    background-color: transparent;
}

.toggle-button:after {
    height:1px;
    background-color:black;
    bottom:calc(100% / 4);
}
.shop img {
  width: 1.75em;
}
.vl {
  border-left: 0.5px solid black;
  height: 24px;
}
.playstore {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.playstore:hover {
  transform: scale(1.01);
  font-weight: bold;
  transition: all 0.2s ease-out !important;
}
.playstore p {
  margin: 0 !important;
}
.logo-container img {
  width: 7em;
}

.images-container .img1 {
  background: url("../assets/banners/img1.png") no-repeat;
  background-size: cover;
}
.images-container .img2 {
  background: url("../assets/banners/img2.png") no-repeat;
  background-size: cover;
}
.image-titles {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 1em 0;
  color: #fff;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.image-titles h2 {
  font-family: 'gotham-b';
}
div.image-titles a
{
  margin-top: 2rem;
}
.image-titles h4
{
  font-family: 'gotham-l';
}
.images-container .img1,
.images-container .img2 {
  height: 500px;
  min-height: 250px;
  text-align: center;
}
.btn-primary,
.btn-secondary {
  width: 100%;
  min-width: max-content;
  padding: 0.4rem 0rem;
  margin-bottom: 1rem;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
}
div.svelte-4trrml {
    width: auto !important;
}
.svelte-4trrml button {
    min-width: 'max-content' !important;
}
.btn-primary.disabled, .btn-primary.disabled:hover {
    background: #f2b467;
    color: #fff;
    border: .5px solid #f2b467;
    cursor: no-drop;
}
.btn-secondary.disabled, .btn-secondary.disabled:hover {
    background: #f2b467;
    color: #fff;
    border: .5px solid #f2b467;
    cursor: no-drop;
}
.input-check {
        width: 1em;
        height: 1em;
        margin-top: 0.25em;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 1px solid rgba(0,0,0,.25);
        appearance: none;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
}
.input-check[type=radio] {
    border-radius: 50%;
}
.input-check:checked[type=radio] {
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23fff%27/%3e%3c/svg%3e);
}

.btn-secondary.white {
  color: white;
  border-color: white;
  border-radius: 2px;
  width: 350px;
  min-width: 250px;
  font-family: 'gotham-l';
}
.btn-primary {
  color: #fff;
  background: #ff9817;
  border: 0.5px solid #ff9817;
  transition: all 0.2s ease-out !important;
  border-radius: 2px;

  /* viejo */
}
.btn-secondary {
  background: transparent;
  color: #ff9817;
  border: solid 1px #ff9817;
  transition: all 0.2s ease-out !important;
}
.btn-secondary.mp {
    background-color: #019ee3;
    border-color: #019ee3;
    opacity: .5;
    color: #ffffff;
}
.btn-secondary.mp:hover {
    background-color: #019ee3;
    border-color: #019ee3;
    opacity: .5;
    color: #ffffff;
}
.btn-primary:hover {
  background: #fff;
  color: #ff9817;
  border: 1px solid #ff9817;
  transition: all 0.2s ease-out !important;
}
.btn-secondary:hover {
  color: #fff;
  background: #ff9817;
  border: solid 1px #ff9817;
  transition: all 0.2s ease-out !important;
}
.categories-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
}

h1.mx-auto,
h2.mx-auto,
h3.mx-auto,
h4.mx-auto,
h5.mx-auto,
h6.mx-auto
{
  margin-bottom: 1.5rem; 
}
div.categories-container .mx-auto
{
  font-family: 'gotham-b';
  font-size: 2rem;
  text-align: center;
}
.categories {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: scroll;
  gap: 3em;
  margin-bottom: 1rem;
}
.categories::-webkit-scrollbar {
  display: none;
}
.categories img {
  width: 32px;
}
.categories .categorie-items {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  min-width: max-content;
  padding-bottom: 0.5rem;
  gap: 0 0.5em;
}
div.categorie-items p
{
  font-family: 'gotham-l';
  font-weight: 700; 
}
.categorie-items img {
  height: 29px;
}
.categorie-items img:hover {
  color: #ff9817 !important;
}
.categories .categorie-items:hover {
  fill: #ff9817 !important;
  color: #ff9817;
  padding-bottom: 0.4rem;
  transition: all 0.2s ease-out !important;
  cursor: pointer;
}
.categories .categorie-items p:hover
{
  border-bottom: 1px solid #ff9817;
  transition: all 0.2s ease-out !important;
}
.products {
  display: flex;
  flex-direction: row;
  margin: 0em;
  padding-bottom: 2rem;
  overflow: scroll;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
.products::-webkit-scrollbar {
  display: none;
}
.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: 0.5rem;
  width: 15em;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  max-width: 200px;
}
.produc-card-info {
  width: 100%;
  height: 220px;
  padding: 1rem;
  padding: 1rem 1rem 0 1rem;
  position: relative;
}

.img-conte.col-12.col-md-5 {
  overflow: hidden;
}

.produc-card-info-cotizar
{
    position: relative;
    width: 100%;
    height: 200px;
    padding: 1rem;
    padding: 1rem 1rem 0 1rem;
}
.product-card .btn-secondary 
{
  width: 90%;
  border-radius: 5px;
  position: absolute;
  bottom: 0.6rem;
  margin-bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.product-card .img-card-container {
  width: 100%;
}
.product-card > div > img {
  object-fit: cover;
  width: 100%;
  background: white;
  border-radius: 5px 5px 0 0;
  border-bottom: 0.5px solid #dfdfdf;
  box-sizing: border-box;
  padding: 0.8rem;
  max-width: 200px;
}
.product-card .price {
  margin-top: 0.3rem;
  margin-bottom: 0.4rem !important;
  font-size: 1.2rem;
  font-family: 'gotham-l';
  font-weight: 700;
}
.title,
.title-data {
  margin-bottom: 0.7rem !important;
}
.title-data
{
  font-size: 0.8rem;
  color: #9c9c9c;
}
.text-contain
{
    height: 100px;
    height: fit-content;
    margin-bottom: 1rem;
    position: relative;
}
.comprar-items-contain
{
  padding: 3rem;
}
.cotizar-desc
{
  font-family: 'gotham-l';
}
.btn-cotizar
{
  width: 100% !important;
}
.footer-image {
    margin: 2em 0;
    width: 100%;
    height: 200px;
    background: url("../assets/banners/te-acompañamos.png") no-repeat;
    background-size: cover;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* padding-left: 9rem; */
}
.footer-image p:last-child {
    padding-left: 3.5rem;
}
.empresa p:last-child
{
  padding-left: initial;
}
.footer-image-cotizar {
  margin: 2em 0;
  width: 100%;
  height: 200px;
  background: url("../assets/banners/DescargateNuestraApp_Banner.jpg") no-repeat;
  background-size: cover;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.footer-image p,
.footer-image-cotizar p{
  font-size: 2.5rem;
  font-family: 'gotham-l';
}
.footer-text
{
  width: 80%;
  text-align: left;
  margin: auto;
}
.footer-text-empresa
{
  text-align: left;
}
.empresa-app
{
  margin-left: 4rem;
}
.footer-empresa{
  width: 80%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}
div.footer-image .proyecto
{
  display: inline-block;
  font-weight: bold;
}
.footer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 1em 10em;
  z-index: 100 !important;
}
.footer .direction-container p {
  margin: 0 !important;
  font-family: 'gotham-l';
}
.footer .direction-container .direction {
 font-weight: bold;
}
.footer .pay-methods {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
}
.footer .pay-methods img {
  width: 45px;
  height: 33px;
  background: whitesmoke;
  box-sizing: border-box;
  padding: 0.3rem;
}
img.playstore {
  width: auto !important;
  background: none !important;
  padding: 0 !important;
}
.navbar-sm, .nav-hamburger{
    display: none;
}
.cont-boxes-desc
{
  cursor: pointer;
}

@media (max-width: 920px) {
  .nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .8em 1em;
    align-items: center;
    height: var(--heigth-navbar);
  }
  .navbar-xl {
    display: none;
  }
  .navbar-sm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: #ff9817eb;
    height: calc(100vh - var(--heigth-navbar));
    width: 100%;
    z-index: 9999;
    transform: translate(100%, 0%);
    transition-duration: .2s;
  }
  .nav-hamburger {
    display: inline-block;
  }
  
  .navbar-sm.show {
    transform: translate(0%, 0%);
    transition-duration: .2s;
  }
    .images-container .img1,
    .images-container .img2 {
        width: 100% !important;
  }
  .footer {
    margin: 2em 0;
    display: flex;
    text-align: center;
    gap: 1em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-image,
  .footer-image-cotizar {
    display: none;
  }
  .footer .pay-methods img {
    width: 42px;
  }
}

@media (max-width: 900px) {
  .products {
    width: 100%;
  }
  .tyc {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
    gap: 0em;
  }
}

.fw-bold
{
  font-family: 'gotham-b';
}
div.img-container h5
{
  font-family: 'gotham-l';
}
.card-product.pared > div {
    width: 100%;
}
.pared button.btn-primary {
    margin: 0;
}
.btn-search
{
  width: 10%;
  height: 40px;
  margin-bottom: initial;
  position: relative;
}
.btn-search:hover
{
  background: transparent !important;
}
.btn-search img
{
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.busca-contain
{
  width: 30%;
  display: flex;
  margin-top: 1rem;
}
.input-busca
{
  width: 100% !important;
  height: 40px;
  border-radius: initial;
}
.pared .cont-btn-actions .btn-action{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
}
.titulo-pared {
    margin-top: 1rem;
    border-bottom: .5px solid #b6b6b6;
}
.cont-btn-actions .btn-action:first-child {
    padding-right: 1rem;
}

/* empresa */
  .app-titles {
    margin-bottom: 2.5rem;
  }
  .about-text {
    padding: 0em 5em;
    position: relative;
    top: 50%;
  }
  .about-image-text {
    background: #eeeeee;
  }
  .palermo-contain
  {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .empresa-img {
    width: 50px;
  }
  .box-empresa{
    border: 1px solid #ff9817;
    border-radius: 5px;
    height: 170px;
    min-height: 100px;
    position: relative;
  }
  .box-contain
  {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    justify-content: center;
  }
  .info-boxes {
    width: 70%;
    margin: 5rem auto;
  }
  .cont-boxes > div:nth-child(2) > div {
    margin: 0 0.8rem;
  }
  .footer-image.empresa p {
    font-size: 2rem;
  }
  .item-empresa {
    padding-left: 1rem;
  }
  .cont-info-boxes
  {
    position: relative;
  }
  .info-contain
  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
/* fin empresa */
/*pago*/
.pago-title
{
  font-family: 'gotham-b';
}
.pago-text
{
  font-family: 'gotham-l';
}

@media (max-width: 920px) 
{
  /* empresa */
    .box-contain
    {
      display: block;
      position: initial;
      top: initial;
      transform: none;
      padding: 1rem 0;
    }
    .empresa-img
    {
      margin: auto;
    }
    .box{
      margin: 0.5rem 0;
      padding: 1.5rem 1rem;
    }
    .info-boxes {
      width: 90%;
      padding: 1.5rem 1rem;
    }
    .cont-boxes {
        text-align: center;
    }
    .cont-boxes > div:nth-child(2) > div {
      margin: 0;
    }
    .about-text {
      padding: 2.5rem 1rem;;
    }
    .app-titles
    {
      text-align: center;
    }
  /* fin empresa */

  /* cotizar */
  .cotizar-title{
    display: none !important;
  }
  /* fin cotizar */

}

@media (min-width: 920px)
{
    .cotizar-subitems {
        width: max-content !important;
    }
}
@media (min-width: 922px)
{

    .cont-login-register {
        padding: 0 10%;
    }
    .cont-login > div, .cont-register > div {
        padding: 1.3rem 2.5rem !important;
    }
    .form-contacto {
        padding: 2rem 3.5rem !important;
    }
    .form-contacto .btn-primary, .form-login .btn-primary, .form-register .btn-secondary{
        max-width: max-content;
        padding: 0.7rem 5rem;
    }
    .cont-form > div {
        width: 80% !important;
    }
    .cont-form {
        display: flex;
        justify-content: center;
        padding: 3.5rem 0 !important;
    }
    .inputs-cols-1 {
        margin-right: .5rem;
    }
    
    .inputs-cols-2 {
        margin-left: .5rem;
    }
}



.form-contacto {
    background-color: white;
    padding: .5em;
    margin-top: 1rem;
    border-radius: 5px;
}

.cont-form h1, .cont-form > div > p {
    padding-left: .5rem;
}
.form-contacto label, .form-login label, .form-register label {
    margin: 1rem 0 .3rem;
}

.form-contacto input, .form-login input, .form-register input {
    height: 3rem;
}
.form-contacto .btn-primary, .form-login .btn-primary, .form-register .btn-secondary {
    margin-top: 1rem;
}
.box-item-contacto {
    margin-bottom: 1.5rem;
}
.cont-info-boxes {
    background-color: white;
}
.cont-info-boxes h2 {
    margin-bottom: 2rem;
}

.cont-form {
    background-color: #f2f2f2;
    padding: 1.5rem .4rem;
}
.cont-info-boxes h4 {
    color: #f1972b;
}

.map-container {
  height: 25vh;
  z-index: 0;
}
.info-body {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.container-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 3em;
  height: 65%;
}

@media (max-width: 920px) {
  .info-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .cont-items-best
  {
    flex-wrap: wrap !important;
    justify-content: center;
    margin: 2rem 0;
  }
}
.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  background: url("../assets/banners/te-acompañamos.png") no-repeat;
  background-size: cover;
  color: white;
  text-align: center;
}
.img-container.comprar {
    background: url("../assets/banners/buscarproductos_banner.png") no-repeat;
    background-size: cover;
  }
.img-container Input {
  width: 40%;
  background: white;
}
.form-contacto input[type="text"], .form-contacto input[type="email"], .form-contacto textarea, .form-login input, .form-register input {
    width: 100%;
    border: .5px solid #d9d8d8;
    border-radius: 5px;
}
div.side-categories
{
  padding: 0.5rem;
}
div.side-categories .side-text
{
  font-family: 'gotham-l';
  margin-left: 0.5rem;
}
.side-categories:hover,
.side-categories img:hover {
  color: #ff9817;
  fill: #ff9817 !important;
  cursor: pointer;
}

.tyc {
  display: flex;
  justify-content: center;
  gap: 2em;
  padding: 1em 0;
  color: #9c9c9c;
}
hr {
  margin-left: auto;
  margin-right: auto;
  width: 81%;
}

.stepper-checkout div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
.stepper-checkout p {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepper-checkout {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1em;
    padding: 1.2rem 2.5em;
    border-bottom: 0.5px solid #ebeaea;
}
.stepper-checkout img {
  height: 24px;
}
.stepper-checkout > div {
    padding-right: 2.8rem;
}

.table-header {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(auto, auto);
}
.table-header {
  background: #fff0dd;
  padding: 1.5em;
  font-weight: bold;
}



.accordion {
  background-color: #ffffff;
  color: #444;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  border-bottom: 0.5px solid #c2c2c2;
}
.panel {
  background-color: white;
  max-height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.item-panel {
    padding: 1rem 18px;
    border-bottom: .5px solid #d1d1d1;
    background-color: #f1f1f1;
    padding-left: 1.6rem;
}
/* tabla carrito */

.table-body {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(auto, auto);
  padding: 1.5em;
}
.table-container {
  display: grid;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.icon-container {
  display: flex;
  gap: 1em;
  margin-bottom: 1em;
}
.last-container {
  display: flex;
  align-items: center;
  gap: 5em;
}

.checkout-container {
  margin-top: 1rem;
}

.row.checkout-container.fw-bold .col-md-5.col-12.d-flex .button-container
{
  padding-left: 0.6rem;
}

.cont-gral-table-resumen {
    margin: 0 2.5rem ;
}
.price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #ebeaea;
  border-radius: 5px;
  padding: 1.3rem 0;
}
.button-container {
  display: flex;
  justify-content: space-evenly;
  gap: 1em;
  align-items: center;
  width: 100%;
}
.button-container .btn-primary, .button-container .btn-secondary {
    margin-bottom: 0;
}
.stepper-checkout .active p {
  color: #ff9817;
  border-bottom: 1px solid #ff9817;
  padding-bottom: 5px;
}
.stepper-checkout .active img {
  filter: invert(61%) sepia(99%) saturate(1337%) hue-rotate(347deg)
    brightness(101%) contrast(101%);
}
.login-title, .register-title {
  color: #ff9817;
  border-bottom: 1px solid #ff9817;
  padding-bottom: 0.6em;
  font-size: 1.8rem;
}
.cont-login > div, .cont-register > div {
    background-color: white;
    padding: 1.5rem .8rem;
    border-radius: 5px;
    border: .5px solid #e1e1e1;
}

.cont-login, .cont-register {
    padding: .5rem;
    margin: .5rem 0;
}
.log-window {
  display: flex;
}




input[type="radio"]:checked {
  background-color: #ff9817;
  color: #ff9817 !important;
  content: "";
  border: 1px solid #ff9817 !important;
  background-clip: content-box !important;
}

.link-carrito {
  position: relative;
}
.cart-circle {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  color: white;
  width: 1.5em;
  height: 1.5em;
  z-index: 100;
  right: -7px;
  top: -4px;
  background: #f19d3e;
  border-radius: 100%;
  font-size: 0.7rem;
}
.cart-circle.active {
  display: flex;
}
.cotizar-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 3rem 1.8rem;
  width: 100%;
  background: url("../assets/banners/img2.png") no-repeat;
  background-size: cover;
  margin-bottom: 1.5rem;
}

/* cotizar  */
.cont-categories {
    flex-wrap: nowrap;
}
  
.cotizar-items {
  cursor: pointer;
  height: 220px;
  width: 20%;
  align-items: center;
  margin-bottom: 1em;
  margin-right: 0.5em;
  position: relative;
  transition-duration: .2s;
  border-radius: 10px;
}
.cotizar-items.selected {
    background-color: #ff9818;
}
.cotizar-items.selected img {
    opacity: .5;
}
.cont-categories.selected > .cotizar-items {
    height: 5em;
    width: 15em;
    transition-duration: .4s;
}
.cont-categories.selected .cotizar-items p {
    padding: .5em;
    min-width: max-content;
}
.cotizar-items img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.cotizar-items:hover p
{
  background-color:rgba(0,0,0,.8);
}

.cotizar-items p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    text-align: center;
    padding: 1em;
    color: #fff;
    box-shadow: 0 0 0px 1px white;
    background-color: rgba(0,0,0,.3);
    text-shadow: -1px 1px black;
    transition: background 0.3s linear;
}
  
.cotizar-subitems {
    cursor: pointer;
    height: 4.5em;
    width: 68vw;
    max-width: 255px;
    display: flex;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    justify-content: space-around;
    align-items: center;
    margin: 1rem 0;
    overflow: hidden;
    margin-right: 1rem;
}

.cont-text-filters {
    margin-top: .5rem;
}
  
.cotizar-subitems:hover {
    color: #fff !important;
    background-color: #ff9817;
    transition: all 0.2s linear !important;
}
.cotizar-subitems p {
    width: 10em;
    text-align: right;
    padding: 1em;
    z-index: 100;
}
.cotizar-subitems img {
    z-index: 1 !important;
    height: 100%;
    width: 40%;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}

.circle-subcategorias {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10em;
    width: 5em;
    margin-right: 1em;
    border-radius: 0 100% 100% 0;
    background-color: #ff9817;
    z-index: 0;
}
.cont-categories.selected
{
  flex-wrap: wrap;
}
.cont-filters
{
  width: 80%;
  margin: auto;
}
.item-filter {
    border: 0.5px solid gray;
    padding: 0.3rem 0rem 0.3rem 0.6rem;
    width: max-content;
    margin: 0.5rem 1rem 0.5rem 0;
    border-radius: 3px;
    max-width: 70vw;
    text-align: revert;
    display: flex;
    align-items: center;
}
/*.item-filter:first-child {
    margin-left: 10rem;
}*/
.close-item-filter {
    padding: 0 1rem 0 .8rem;
    cursor: pointer;
}
.cont-items-best {
    overflow-y: scroll;
}
.cat-contain{
  justify-content: center;
}
.cont-text-category {
}

.item-product-combinado {
    width: auto;
}

.cont-sucategory-results {
    padding-bottom: 1.5rem;
    max-width: 100vw;
    box-sizing: border-box;
    flex-wrap: wrap;
}
.cont-sub-result {
    margin-top: 1.5rem;
    width: 80%;
    margin: auto;
}
  /* cotizar */
.resultados-contain
{
  display: flex;
  flex-wrap: wrap;
}
.card-product {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 70%;
  padding: 1.5rem;
  margin: 2em auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.contenedor-botones div:last-child {
    padding-left: 1rem;
}
.contenedor-botones {
    margin-top: 1rem;
}
.card-product img {
  width: 100%;
}
.info-card-product {
    padding-left: 1.5rem;
    position: relative;
}
.contador {
  display: flex;
  justify-content: space-around;
  border: 1px solid #d8d8d8;
  width: 100%;
  padding: 0.8rem 0;
}
.contador .button-change-count {
    cursor: pointer;
    padding: 0 1rem;
}
.cotizar-table {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: auto;
  padding: 0 !important;

  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.header {
  color: #000;
  background-color: #fdf3ea;
  padding: 1em 4em;
  height: 5em;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 20% 20%;
  justify-content: space-around;
  align-items: center;
}
.items-container {
    padding:1em 4em;
    width: 100%;
    display: grid;
    grid-template-columns: 60% 20% 20%;
    justify-content: start;
    align-items: center;
    border-bottom: 0.5px solid #cdcdcd;
}

.items-container div{
    margin: 1rem 0;
}

.cotizar-table .cotizar-cantidad, .cotizar-table .productos-cantidad-cotizar, .cotizar-table .cotizar-precio, .cotizar-table .productos-subtotal {
    text-align: center;
}
.table-footer {
    display: grid;
    grid-template-columns: 60% 20% 20%;
    width: 100%;
    padding: 1em 4em;
    height: 6em;
    align-items: center;
    background: #ebebeb;
}

/* checkout */
.checkout .header, .table.accordion, .panel .item-panel {
    display: grid;
    grid-template-columns: 40% 15% 15% 15% 15%;
}
.order-top {
    border: 1px solid #ff9818;
    padding: 1.3rem;
    border-radius: 5px;
    margin-bottom: 1.5rem;
}

.date-order {
    padding-left: 1rem;
}
.cont-detal-order {
    display: flex;
    flex-direction: row;
}
.cont-detal-order p:first-child {
    padding-right: 1rem;
}
.card.order {
    padding: 2.5rem;
    width: 80%;
    max-width: 920px;
    margin: auto;
}
.order-top div:last-child {
    text-align: end;
    padding-right: 1rem;
}
.cont-payment-method {
    border-top: .5px solid #c8c9ca;
    padding-top: 1.5rem;
}
.cont-options-method {
    display: flex;
    flex-direction: row;
}
label.radio-payment {
    min-width: max-content;
    border: .5px solid #c8c9ca;
    padding: .6rem 1.5rem;
    border-radius: 5px;
}
label.radio-payment input {
    margin-right: .5rem;
}
.cont-btn-action-payment {
    display: inline-block;
    max-width: max-content;
}
label.radio-payment:active {
    border: 1px solid #ff9818;
}
.radio-payment.mp {
    margin: 0 1rem;
}
.res-pago {
    display: flex;
    justify-content: center;
    height: 45px;
    border: .5px solid;
    border-radius: 5px;
    align-items: center;
    margin: 1rem 0;
}
.btn-primary.pago-ok {
    background: #27c25f;
    cursor: inherit;
    border: none;
}

.btn-primary.pago-ok:hover {
    color: white;
    border: none;
}
.msj_error_mp {
    display: flex;
    color: red;
    padding: 1rem;
}

#btn-cont
{
  margin-top: 1rem;
}
.btn-back
{
  text-align: center;
  width: 50%;
  margin: 1rem auto 0;
}
.cont-radios {
    margin-top: 1rem;
}
.modal-email {
    position: fixed;
    background: #0000003d;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99999;
}
.cont-modal-email {
    width: auto;
    height: auto;
    padding: 1rem 3rem;
    background: white;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top: 4px solid #ff9818;
    border-radius: 5px;
}
.title-modal-email {
    text-align: center;
    border-bottom: 1px solid #ff9818;
    margin-bottom: 1rem;
    padding: .5rem 0 1rem;
    color: #ff9818;
    font-size: 1.2rem;
}

span.close-modal-email {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-150%, 50%);
    cursor: pointer;
}
span.close-modal-email:hover {
    scale: 1.1;
    transition-duration: .2s;
}

.cont-btn-action-new-purchase .btn-primary {
    max-width: max-content;
}
.info p:nth-child(2) {margin-top: 1rem;}

.cont-btn-action-new-purchase {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

.cont-modal-email .info {
    text-align: center;
}
.input-mp {
    width: 100%;
}
.spinner-border {
    width: 1rem !important;
    height: 1rem !important;
    border-width: .15em !important;
}
/* fin checkout */


/* loading product */
.btn-category.loading {
  min-width: 10rem;
  height: 2.3rem;
}
.loading-content {
  padding: 0.5rem;
}
.loading-content .img-card-container {
  height: 12rem;
}
.loading-content .product-card {
  padding: 0.5rem;
  width: 14em;
}
.loading-content .produc-card-info {
  padding: 0.5rem 0;
}

.loading-content .price {
  width: 50%;
  height: 1rem;
}

.loading-content .title {
  width: 90%;
  height: 2.5rem;
}

.loading-content .btn {
  height: 40px;
  width: 100%;
}
/* efecto loading */
.loading {
  position: relative;
  background-color: #e2e2e2;
  border-radius: 3px;
}
/* movimiento */
.loading::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.2)),
    to(transparent)
  );
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  /* agregando animacion */
  animation: loading 0.8s infinite;
}
/* loading animacion */
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.cont-caract-prod {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.title-price {
  border-bottom: 0.5px solid black;
  margin-bottom: 1.2rem;
  padding-bottom: 1.2rem;
}

.img-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.5px solid black;
  padding-bottom: 1.3rem;
}

.cont-img {
  border: 0.5px solid #d8d8d9;
  border-radius: 5px;
  padding: 0;
}
.cont-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) 
{
  .cont-info-boxes
  {
    height: 550px;
  }
}
@media (max-width: 920px) 
{
    /*home*/
    .images-container .img1, .images-container .img2 {
        height: 304px;
        min-height: 250px;
        text-align: center;
    }
     /*footer*/
    .logo-container
    {
      width: 100% !important;
    }
    .didesk {display: none;}
    /* navbar */
    .playstore.navbar{
        display: none;
    }
    /* fin navbar */
    /* comprar */
    .comprar-items
    {
      width: 90% !important;
      margin: auto;
      text-align: center;
    }
    .side-categories
    {
      justify-content: center;
      min-width: max-content;
    }
    .categories-filter
    {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100% !important;
      flex-wrap: wrap;
    }
    .cont-filters{
      justify-content: center;
    }
    .compra-contain
    {
      text-align: center;
      margin-top: 2rem;
    }
    .comprar-produ
    {
      width: 100% !important;
      margin-top: 2rem;
    }
    .search-compra
    {
      width: 100% !important;
    }
   /* cotizar */
    .cont-categories {
        display: block;
    }
    .sub-tit
    {
      text-align: center;
      margin: 1rem 0;
    }
    div.cont-categories .cotizar-items
    {
        width: 100% !important;  
    }
    .cont-text-category {
        text-align: center;
        margin: 1.5rem 0;
    }
    /*.cotizar-subitems:first-child {
        margin-left: 1rem;
    }*/
    .cont-text-filters {
        padding-left: 1rem;
    }
    .item-filter:first-child {
        margin-left: 1rem;
    }
    .cont-sucategory-results {
        padding: 0rem 1rem 1rem 0;
    }
    .cont-best-sellers {
        padding-left: inherit;
    }
    .cont-sucategory-results {
        overflow-x: scroll;
    }
    .resultados-contain
    {
      justify-content: center;
      margin-right: initial;
    }
    .cant-contain,
    .btn-cotizar-contain,
    .btn-agregar-contain
    {
      width: 100% !important;
    }
    #btn-agregar
    {
      width: 100%;
    }
    .contenedor-botones
    {
      display: block;
    }

   /* fin cotizar */

   /* producto */
    .card-product {
        width: 90%;
        padding: 1rem;
    }
    .info-card-product {
        padding-left: inherit;
    }
    .cotizar-table {
        width: 90%;
    }
    .header, .cotizar-table .items-container, .cotizar-table .table-footer {
        padding: 0.8rem;
        grid-template-columns: 52% 20% 26%;
    }
   /* fin producto */

   /* checkout */
       .cont-gral-table-resumen {
            margin: 0 1rem;
        }
        .button-container {
            padding-left: 0;
            margin-top: 1rem;
            flex-direction: column;
        }
        .stepper-checkout {
            padding: 1.2rem 1em;
        }
        .stepper-checkout > div {
            padding-right: 1.2rem;
        }
        .table-container.checkout {
            font-size: .7rem;
        }
        .cont-detal-order {
            flex-direction: column;
            text-align: center;
        }
        .card.order {
            padding: 1rem;
        }
        .cont-detal-order p:first-child {
            padding-right: 0;
        }
        .date-order {
            padding-left: 0rem;
        }
        .order-top div:last-child {
            text-align: center;
            padding-right: inherit;
            margin-top: 1rem;
        }
        .cont-options-method {
            flex-direction: column;
        }
        .cont-btn-action-payment {
            display: block;
            max-width: inherit;
            margin-top: .5rem;
        }
        label.radio-payment {
            width: 100%;
            margin: .5rem 0 !important;
            padding: 1rem;
        }
        .cont-modal-email {
            width: 90vw;
            margin-top: 2rem;
        }
   /* fin checkout */
      
      .btn-secondary,
      .btn-primary {
        width: 20%;
        text-align: center;
      }
      div.cont-options-method .btn-primary,
      div.cont-options-method .btn-secondary
      {
        width: 100% !important;
      }
      .cont-img {
        height: 13rem;
        width: 13rem;
        margin: auto;
        margin-bottom: 1rem;
      }
      .image-titles {
        padding: 1rem 0;
      }
      .images-container .text-img:first-child {
        /*display: none;*/
      }
      .categories {
        padding-right: 2em;
        padding-left: 2rem;
        flex-wrap: wrap;
        display: block;
      }
      .categorie-items
      {
        margin: 1rem 0;
      }
      .categories-loading {
        gap: 1rem;
      }
      .footer {
        padding: 1em 0.5em;
      }
      .img-container Input {
        width: 80%;
      }

    .cont-btn-actions .btn-action:first-child {
        padding-right: 0;
    }
    .contenedor-botones div:last-child {
        padding-left: 0;
    }
    .contenedor-botones .btn-primary {
        margin-top: 1rem;
    }
    .about-text
    {
      width: 100%;
      text-align: center;
    }
    .cont-boxes
    {
      display: block;
    }
    div.cont-boxes .cont-boxes-desc
    {
      width: 100%;
      padding: 1rem 0;
    }
    .box-empresa
    {
      margin: initial !important;
    }
    .about-image
    {
      display: none;
      width: 100% !important;
    }
    div.info-contain .contacto-title
    {
      margin-top: 1rem;
      text-align: center;
    }
}
@media (max-width: 640px){
  .product-card
  {
    width: 100% !important;
    margin:  0 2rem 1rem;
  }
  .busca-contain
  {
    width: 90%;
    margin: auto;
  }
} 
.card-product .foto-cotizar {
  width:20em;
}

.button-add{
  display:flex;
  justify-content:center;
  width:30%;
  margin:0 auto;
}

/*accordion compra*/
.accordion
{
  border-bottom: initial;
}
.accordion-item
{
  border: none !important;
}
.accordion-buttom
{
  padding: initial;
}
.accordion-button:not(.collapsed)
{
  width: 150px;
  background-color: transparent !important;
}
/*modal-carrito*/
.product-title
{
  font-family: 'gotham-b';
}
.product-contain
{
  font-family: 'gotham-l';
}
.product-price
{
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.line
{
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  margin-top: 1.5rem;
  background-color: #9c9c9c;
}
.line-dos
{
  content: "";
  width: 100%;
  height: 1px;
  background-color: #9c9c9c;
}
.product-desc
{
  margin-top: 2rem;
}

/*palermo empresa y contacto*/
.palermo-title,
.contacto-title
{
  font-family: 'gotham-b';
}
.palermo-desc,
.contacto-data
{
  font-family: 'gotham-l';
  font-weight: 700;
}
.contacto-data
{
  color: #ff9817;
}
.title-empresa
{
  font-family: 'gotham-l';
  font-weight: 700;
  color: #ff9817;
  font-size: 1.2rem;
}
.desc-empresa
{
  font-family: 'gotham-l';
}
.input-title
{
  font-family: 'gotham-l';
}

/*cotizar*/
.cot-title
{
  font-size: 1.5rem;
}
.cot-subt
{
  font-family: 'gotham-l';
  font-size: 1.5rem;
}
.sub-tit
{
  font-family: 'gotham-b';
}
.cot-subt-search
{
  font-family: 'gotham-l';
  font-size: 1rem;
}
.cont-gral-category
{
  width: 80%;
}
.img-product.loading {
    width: 300px;
    height: 300px;
}
.descrip-card.loading {
    width: 100%;
    height: 3rem;
}
.cont-gral-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4rem;
}
.popup-add-cart {
    position: absolute;
    background-color: #ff9818;
    border: 1px solid #ffffff;
    border-radius: 3px;
    color: white;
    font-weight: bold;
    max-width: max-content;
    padding: 1rem;
    z-index: 999;
    right: -18px;
    margin-right: 0rem;
    margin-top: 4rem;
    opacity: 0;
    transition-duration: .2s;
}
.popup-add-cart.show {
    right: 2rem;
    opacity: 1;
    transition-duration: .2s;
}
.image-titles .btn-primary {
    max-width: 63%;
}

.cont-validate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25%;
    margin-top: 25% !important;
}

.valida-fallo, .valida-ok {
    text-align: center;
    font-size: 2rem;
}
.valida-fallo {
    color: #c11919;
}
.valida-ok {
    color: #ff791d;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

.input-cant input[type="number"] {
    width: fit-content !important;
    min-width: unset;
    text-align: center;
    max-width: 80px;
    border: none;
    border-bottom: 1px solid #cdcdcd;
}

.img-conte.col-12.col-md-5 img
{
  min-width: 143px !important;
    max-width: 220px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.img-conte.col-12.col-md-5 
{
  overflow: hidden;
}

.img-card-container {
    width: 200px;
    height: 200px;
}

.img-card-container img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.cont-items-best .product-card {
    margin-right: .5rem;
}
.cant-contain {
    min-width: max-content;
}
.table.accordion.active {
    border-top: .5px solid #d0d1d1;
    border-bottom: .5px solid #d0d1d1;
}